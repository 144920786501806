import React from 'react';
import { DateService } from '@apollo/core';
import classNames from 'classnames';
import useTranslate from '../../../src/app/shared/components/I18n/Interpreter';
import { transactionTypes, WITHDRAWAL_CREATE } from '../../../src/app/core/constants';
import { getCurrencySymbol } from '../../../src/app/core/utils';
import Label from '../../../src/app/shared/components/I18n/Label';
import TransactionCancelButton from '../../../src/app/features/ClientProfile/HistoryTab/TransactionHistory/TableList/TransactionCancelButton';
import TransactionAmount from '../../../src/app/features/ClientProfile/HistoryTab/TransactionHistory/TableList/TransactionAmount';
import TransactionEventDetails from '../../../src/app/features/ClientProfile/HistoryTab/TransactionHistory/TableList/TransactionEventDetails';
import TransactionOdds from '../../../src/app/features/ClientProfile/HistoryTab/TransactionHistory/TableList/TransactionOdds';
import BetSourceTooltip from '../../../src/app/features/ClientProfile/HistoryTab/BetHistory/TableList/BetSourceTooltip';

const TransactionHistoryItemMobile = ({
  transaction,
  outcomes,
  isBetTransaction,
  flexiPercentage,
  disableCancel,
  decimalCashBalance,
  cancelWithdrawalHandler,
  oddsAmount,
  originalBetOdds,
  bonusInfoList,
  bonusType,
}) => {
  const { id, type, creationDate, currency, properties } = transaction;

  const t = useTranslate();

  const outcomeInfo = outcomes && outcomes[0];

  const date = DateService.getFormattedDate(creationDate);
  const time = DateService.getFormattedTime(creationDate);
  const currencySymbol = getCurrencySymbol(currency);

  return (
    <div
      key={transaction.creationDate}
      className={classNames('history-item', {
        isPositive: type === transactionTypes.BET_WIN,
      })}
    >
      <div className='history-item__header'>
        <div className='history-item__type'>
          <span>{t(type)}</span>
          <BetSourceTooltip bonusInfoList={bonusInfoList} />
        </div>
        <div className='history-item__header-date'>
          <span>{date}</span>
          <span className='sub-title'>{time}</span>
        </div>
      </div>
      <div
        className={`event-container ${
          outcomeInfo?.sameGameMultiLegs?.length ? 'event-container--baseline' : ''
        }`}
      >
        {isBetTransaction ? (
          <TransactionEventDetails
            outcomes={outcomes}
            transaction={transaction}
            flexiPercentage={flexiPercentage}
          />
        ) : (
          <span>{properties?.PM_NAME ?? '-'}</span>
        )}
        <div className='history-item__header-id'>
          <Label message='ticket id' />
          &nbsp;
          {id}
        </div>
        <div className='history-item__balance'>
          <div className='bet-stake'>
            <Label message='debit' className='text-nowrap' />
            <TransactionAmount
              transaction={transaction}
              bonusInfoList={bonusInfoList}
              bonusType={bonusType}
            />
          </div>

          {oddsAmount ? (
            <div className='bet-odds'>
              <Label message='odds' className='text-nowrap' />
              <TransactionOdds
                betOdds={oddsAmount}
                outcomes={outcomes}
                originalBetOdds={originalBetOdds}
              />
            </div>
          ) : null}

          <div className='bet-balance'>
            <Label message='balance' className='text-nowrap' />
            <span className='balance'>{`${currencySymbol}${decimalCashBalance}`}</span>
          </div>
        </div>
      </div>
      {!disableCancel && type === WITHDRAWAL_CREATE && (
        <div className='verify-status'>
          <TransactionCancelButton onCancel={cancelWithdrawalHandler} />
        </div>
      )}
    </div>
  );
};

export default TransactionHistoryItemMobile;
