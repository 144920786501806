import React from 'react';
import { useHistory } from 'react-router-dom';
import Accordion from '../../../src/app/shared/components/Accordion/Accordion';
import Label from '../../../src/app/shared/components/I18n/Label';

const PaymentMethodList = ({ type, payments = [], route, paymentId, active, children }) => {
  const { push: historyPush } = useHistory();

  const onToggle = (paymentId, isCollapsed) => {
    if (!isCollapsed) {
      historyPush(`${route}/${paymentId}#ns`);
    }
  };

  return (
    <div className='payments payments-list'>
      {payments.map((payment, index) => (
        <Accordion
          title={(
            <div className='wrapper__payments'>
              <div className={`logo logo--${payment.depositType?.toLowerCase()}`} />
              {payment.name}
            </div>
          )}
          className='eventView__market-group'
          collapsed={paymentId ? paymentId !== `${payment.id}` : index > 0}
          key={payment.id}
          onToggle={(isCollapsed) => onToggle(payment.id, isCollapsed)}
          isOneActive
        >
          <div className='payments-list__description'>
            <Label message={`${type} ${payment.name} description`} />
          </div>
          {active && children}
        </Accordion>
      ))}
    </div>
  );
};

export default PaymentMethodList;
